import type { NextRouter } from "next/router";
import type { SWRConfiguration } from "swr/_internal";
import type { ILanguageType } from "./user";

export type IEnvs = "dev" | "beta" | "pre" | "prod" | "red";

export type IAPPType = {
  android: string;
  ios: string;
};
export interface IEnvConfig {
  env: IEnvs;
  host: {
    dev: string;
    prod: string;
  };
  klineChartUrl: string;
  tvChartUrl: string;
  websocket: {
    host: string;
  };
  webDomain: string; // web域名
  appOnlyDomain: string; // apponly 域名
  domainUriPrefix: string; //firbase配置的王域前缀
  apiKeyBugsnag: string;
  fallbackUrl: IAPPType;
  packageName: {
    android: string;
    ios: string;
  };
  minimumVersion?: {
    android: string;
    ios: string;
  };
  operationServer: string;
  saasServer: string;
  appScheme: string;
}

export interface IPagerInfo {
  page: number;
  nextToken: string;
}

// 通用的，或者一些分类不明确，或者业务声明较少的类型，放在这里了～
// 通用接口返回数据字段
export interface IRes {
  code: string | number;
  msg: string;
  data: any; // 暂不修改
  obj?: any; // 暂不修改
  time?: number;
  msgKey?: string;
  success?: boolean;
  consecutiveErrors?: boolean;
  verifyStatus: string | number;
}

export interface ISSRParmas {
  headers?: ISSRHeaders;
  nextToken?: string;
}

export interface ISSRHeaders {
  jwtToken: string;
  language: string;
  domain: string;
  source: string;
  device_id: string;
  agentOperatingToken?: string;
  customerManagerOperatingToken?: string;
  REFSFGADEF?: string;
}
/**
 * 开发环境的minimumVersion配置说明：
 * 1. 开发环境的minimumVersion, android和awesome_aspen_app/android/ci-config.properties配置里versionCode保持一致, iOS的和awesome_aspen_app/ios。如果安装的是release环境的包，参考2或者3
 * 2. beta环境的minimumVersion, android在 http://192.168.51.2:8080/view/Beta/job/aspen_android/ 上查找制定版本的构建id, iOS在http://192.168.51.2:8080/view/Beta/job/aspen_ios/ 上查看某版本的版本号
 * 3. prod环境的minimumVersion, android和googleplay最新版本保持一致，参考：https://developer.apple.com/library/archive/technotes/tn2420/_index.html， ios从appstore查找特定版本的版本号
 *
 */
export interface ISSRRedirect {
  destination: string;
  permanent: boolean;
}

export type ILoading = "loading" | "error" | "empty" | "success";
interface IBaseProps {
  router: NextRouter;
}
export interface IInjectProps extends IBaseProps {}

/* --------------------  审批相关 -------------------- */

export interface IReqApplicationList {
  offset: number;
  limit: number;
}
export interface IApplicationList {
  approveEmail: string;
  approveType: number;
  exesStatus: number;
  detail: string;
  flowStatus: number;
  flowType: string;
  processInstanceId: string;
  startTime: number;
  status: number;
}
/* -------------------- end 审批相关 --------------------*/

/* -------------------- 账务历史 -------------------- */
export interface IReqAccountHistory {
  currency: string;
  offset: number;
  limit: number;
}
/* -------------------- end 账务历史 -------------------- */

/* -------------------- 交易history相关 -------------------- */
// todo 具体类型定义待接口确定后修改
export interface IHistroyCategoryDetail {
  key: string;
  date: string;
  pair: string;
  type: string;
  side: string;
  price: number | string;
  currency: string;
  amount: string | number;
  total: string | number;
  fee: string | number;
  status: string | number;
}
/* --------------------end 交易history相关-------------------- */

/* --------------------overview相关-------------------- */
// todo 具体类型定义待接口确定后修改
export interface IChartData {
  key?: string | number;
  type: string;
  value: number;
  percent?: number;
}

export interface IUploadQuery {
  path: string;
}
export interface IUploadSignUrl {
  filePath: string;
}
/* -------------------- end overview相关 -------------------- */

// 判断用户是否存在, 貌似暂时不用，确定后可以删除
export interface IReqIfExist {
  userName: string;
}
//
export interface IReqEmailCode {
  emailCode: string;
}

// 上报设备信息
export interface IReqDeviceInfo {
  adId: string;
  deviceId: string;
  deviceType: string;
  networkType: string;
  operationType: number;
}

// 缓存key参数配置
export interface ICacheItemConfig<T> {
  api: string;
  param: T | undefined; // 不需要传参数时，将参数设置为undefined
  options?: SWRConfiguration;
  language?: ILanguageType[] | ""; // 可选参数，language返回值会根据语言区别时需要配置, 不区分语言时，设置为 ""
  cacheResDataKey?: string; // 需要缓存的接口返回数据特定的key，默认是res.data, 部分接口是res.data.rows等
}

export interface ISWRConfig {
  // TODO: key 对应的响应结果没有被删除，需要手动清理缓存，避免内存泄露
  key: string; // 唯一标识符（string / function / array / null）
  fetcher: any; // Partial<PublicConfiguration<T, any, BareFetcher<T>>>;// Partial<PublicConfiguration<T, any, BareFetcher<T>>>; // 任何返回数据的异步函数
  // https://swr.vercel.app/zh-CN/docs/api#options
  options?: SWRConfiguration;
}

// 安全验证公共header参数
export interface IReqSecurityVerifyHeader {
  emailCode?: string;
  phoneCode?: string;
  googleCode?: string;
  businessType: number;
  smsType?: 0 | 1; // 手机验证码类型，0：文本，1：语音
}

/**
 * 业务模块安全验证需要的header
 */
export interface IBusinessVerificationHeaderModel {
  emailCode?: string;
  phoneCode?: string;
  googleCode?: string;
}

export interface IBtcYtdRes extends IRes {
  data: IBtcYtdData;
}

export interface IBtcYtdData {
  ytdTrends: IYtdTrend;
}

export interface IYtdTrend {
  YTD_BTC: IYtdTrendList;
  YTD_SP: IYtdTrendList;
}

export interface IYtdTrendList {
  bars: string[];
  ticker: { percentage: number; timestamp: number; price: number };
}
export interface ICountriesData {
  chineseName?: string;
  commonName: string;
  displayName?: string;
  enable?: boolean;
  formalName?: string;
  id: string;
  countryCode: string;
  nationality: string;
  localName?: string;
  nationalFlag: string;
  startChar?: string;
  telephoneCode: string;
  flagIsImage?: boolean;
}
/*
  埋点上报的参数类型
*/
export type GA_PARAMS = Record<string, string | number | boolean>;

// 公用接口和参数一致的请求，可以在这里配置
export const COMMON_CACHE_PRIORTY_KEYS = {
  walletInfo: "walletInfo",
  strategies: "strategies",
  primaryVentureList: "primaryVentureList",
  secondaryVentureList: "secondaryVentureList",
  ventureDetail: "ventureDetail",
  strategiesDetail: "strategiesDetail",
  depositCoinList: "depositCoinList",
  spotAccounts: "spotAccounts",
  assetsLineData: "assetsLineData",
  yieldFlexibleProducts: "yieldFlexibleProducts",
  yieldFixedProducts: "yieldFixedProducts",
  stakingProducts: "stakingProducts",
  autoInvestList: "autoInvestList",
  eventsList: "eventsList",
  eventsDetail: "eventsDetail",
  venturePositions: "venturePositions"
} as const;

// 新闻类型（STORY 爬虫新闻、NEWS_FLASH 快讯、 NOTICE 公告消息、BRIEF 简报、REPORT 研报、WEEKLY_REPORT 周报、EXPERT_POST 专栏）
export const NEWSTYPE = {
  news: "STORY", // 爬虫新闻
  feed: "NEWS_FLASH", // 快讯
  notice: "NOTICE", // 公告消息
  daily: "BRIEF", // 简报
  research: "REPORT", // 研报
  weeklyReport: "WEEKLY_REPORT", // 周报
  monthlyReport: "MONTHLY_REVIEW", // 月报
  expert: "EXPERT_POST" // 专栏
} as const;

// 和firebase控制台保持一致的事件参数，为了复用 APP的 notifyNavigateWithType
export const EVENT_ACTIONS = {
  globalMarket: "global_market", // 行情推送
  reviewDetail: "flowable-process", // 审批
  vipDetail: "jump.vip.detail", // vip等级变更
  socialPostPublish: "social_post_publish", // 专栏作者发布新文章
  externalJump: "external_jump", // 控制台配置跳转外部链接
  newsDetail: "NewsDetail", // 新闻详情
  briefingDailyDetail: "BriefingDailyDetail", // 日报详情
  briefingWeeklyDetail: "BriefingWeeklyDetail", // 周报详情
  researchDetail: "ResearchDetail", // 研报详情
  expertDetail: "ExpertDetail", // 专栏详情
  expertAuthor: "ExpertAuthor", // 作者详情
  discovery: "Discovery", //发现
  profile: "Profile", //个人中心
  notificationlist: "Inbox_Notification", //通知
  reviewingList: "Inbox_Review_Reviewing", //待审批
  reviewedList: "Inbox_Review_Reviewed", //已审批
  marketExchangeList: "Market_Exchange", //全部盘口
  marketSuggestedList: "Market_Suggested", //推荐盘口
  ventureList: "Investment_Venture", //风投
  strategiesList: "Investment_Strategies", //策略
  flexibleList: "Investment_Yield_Flexible", //活期
  fixedList: "Investment_Yield_Fixed", //定期
  stakingList: "Investment_Yield_Staking", //质押
  dcaList: "Investment_Yield_AutoInvest", //定投
  ventureHistory: "History_Venture", //风投历史
  strategiesHistory: "History_Strategies", //策略历史
  flexibleHistory: "History_Yield_Flexible", //活期历史
  fixedHistory: "History_Yield_Fixed", //定期历史
  stakingHistory: "History_Yield_Staking", //质押历史
  dcaHistory: "History_Yield_AutoInvest", //定投历史
  openOrderList: "History_Trade_OpenOrder", //当前委托
  orderHistory: "History_Trade_OrderHistory", //历史委托
  newsList: "Intelligence_News", //新闻
  researchList: "Intelligence_Research", //研报
  expertList: "Intelligence_Expert", //专栏
  wallet: "WalletInfo", //钱包
  assets: "Assets", //资产分析
  depositFiat: "DepositFiat", //入金
  depositCrypto: "DepositCrypto", //充币
  depositFiatHistory: "History_Deposit_Circle", //入金历史
  withdrawFiatHistory: "History_Withdraw_Circle", //出金历史
  depositCryptoHistory: "History_Deposit_Crypto", //充币历史
  withdrawCryptoHistory: "History_Withdraw_Crypto", //提币历史
  resetPassword: "Login_Reset", // 重置密码
  register: "Register", // 注册(携带邀请码)
  conferenceDetail: "ConferenceDetail" // event活动详情
} as const;

export type ICommonCacheKeys = keyof typeof COMMON_CACHE_PRIORTY_KEYS;

// 缓存key参数配置
export interface ICacheItemConfig<T> {
  api: string;
  param: T | undefined; // 不需要传参数时，将参数设置为undefined
  options?: SWRConfiguration;
  language?: ILanguageType[] | ""; // 可选参数，language返回值会根据语言区别时需要配置, 不区分语言时，设置为 ""
  cacheResDataKey?: string; // 需要缓存的接口返回数据特定的key，默认是res.data, 部分接口是res.data.rows等
}
