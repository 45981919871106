"use client";
import { Spin } from "antd";
import type { CSSProperties } from "react";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";

type IProps = {
  htmlContent: string;
  style?: CSSProperties;
  withSpin?: boolean;
};

function HtmlContent(props: IProps) {
  const htmlRef = useRef<HTMLBodyElement>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { style, withSpin = true } = props;
  useEffect(() => {
    if (htmlRef.current) {
      const scripts = htmlRef.current.getElementsByTagName("script");
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < scripts.length; i++) {
        // eslint-disable-next-line no-eval
        eval(scripts[i].innerHTML);
      }
      // setTimeout(() => {
      // }, 1000);
    }
    setLoading(false);
  }, [props.htmlContent]);

  return withSpin ? (
    <Spin spinning={loading}>
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          // alignItems: "center",
          justifyContent: "center",
          ...style
        }}
        // @ts-ignore TODO， check @elaine
        ref={htmlRef}
        dangerouslySetInnerHTML={{ __html: props.htmlContent }}
      />
    </Spin>
  ) : (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        // alignItems: "center",
        justifyContent: "center",
        ...style
      }}
      // @ts-ignore TODO， check @elaine
      ref={htmlRef}
      dangerouslySetInnerHTML={{ __html: props.htmlContent }}
    />
  );
}

export const HtmlContentComponent = React.memo(HtmlContent);
