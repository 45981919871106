import React from "react";
import styles from "@aspen/theme/Event.module.less";
import type { ConferenceVO, IEventStatus } from "@aspen/model";
import Image from "next/image";
import { CountdownView } from "./CountdownView";
import SkeletonImage from "antd/lib/skeleton/Image";

interface IProps {
  data: ConferenceVO | undefined;
  status: IEventStatus | undefined;
  width?: number;
  height?: number;
  countStatusEnd?: () => void;
}

const EventBannerView: React.FC<IProps> = (props) => {
  const now = new Date().getTime();
  const { data, status, height = 200, width = 375, countStatusEnd } = props;
  return (
    <div className={styles.bannerView}>
      {data?.image ? (
        <div style={{ position: "relative" }}>
          {status === "coming" ? <div className={styles.eventItemImageBg} /> : null}
          <Image
            style={{ objectFit: "cover", objectPosition: "center" }}
            unoptimized
            src={data?.image || ""}
            alt=""
            width={width}
            height={height}
          />
        </div>
      ) : (
        <SkeletonImage style={{ width, height, background: "#1F212F" }} />
      )}
      {status === "coming" ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: 11
          }}>
          <CountdownView
            key={data?.id}
            startTime={now}
            endTime={data?.startTime || 0}
            size="large"
            countStatusEnd={() => {
              countStatusEnd && countStatusEnd();
            }}
          />
        </div>
      ) : null}
    </div>
  );
};
export const EventBanner = React.memo(EventBannerView);
