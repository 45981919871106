export const COUNT_DOWN_TIMER = 60;

import {
  PARTNER_PATH,
  HOME_PATH,
  LOGIN_PATHS,
  TRADE_PATH,
  STRUCTURED_PATH,
  OPERATION_PATH,
  SAAS
} from "./routerPaths/index";

// 语言配置
export const EN = "en";
export const ZH_CN = "zh";
export const SHOW_LANGUAGE = true; // 语言显示

//dayjs 语言配置
export const DAYJSEN = "en";
export const DAYJSZH = "zh_cn";
// 密码最大长度40
export const PASSWORD_MAX_LENGTH = 40;
// 验证码最长长度6
export const VerifyCode_MAX_LENGTH = 6;

export const MD5_FIXED_STRING = "Awesome Aspen Digital";

export const BRAND = "Aspen Digital";

export const PREFIX = "Web";

export const AGENT = "agent";

export const USDC = "USDC";

export const NOT_LOGIN_CODE = [-1, -3];

export const Months = "dca.chart.monthList";

export const timeFormat = "YYYY-MM-DD HH:mm:ss";
export const dateFormat = "YYYY-MM-DD";

// 长动态链接，不能decode
//https://aspendigital.onelink.me/iWAu?af_xp=social&pid=deeplink&af_dp=AspenDigital%3A%2F%2Fopen&af_force_deeplink=true
export const APP_DOWNLOAD_ADDRESS = "https://aspendigital.onelink.me/iWAu/7sucw5vq";
// 密码格式，数字+小写字母+大写字母，6位以上
export const REG_EXP_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,}$/;
// 0~9任意数字
export const REG_EXP_NUMBER_0_9 = /^[0-9]\d*$/;
// 数字REG
export const REG_EXP_NUMBER = /^-?\d*(\.\d*)?$/;
// positive number
export const REG_EXP_POSITIVE_NUMBER = /^\d*(\.\d*)?$/;
// positive integer
export const REG_EXP_NUMBER_POSITIVE = /^[1-9]\d*$/;

export const RECAPTCHA_V3_KEY = "6Lfrv9odAAAAAAPyzPXB4sEQ1ILa_UMBOj7VEv8z";
export const RECAPTCHA_V2_KEY_CHECKBOX = "6LdMUN0dAAAAAC192e0mY3tDjQxErMRQAPpBXBV4";
export const RECAPTCHA_V2_KEY_INVISIBLE = "6Lek_RoeAAAAAHzk3EGE4Nq-_JdZlBfcuYdt7zQt";
export const apikeyBugsnagProd = "39c2a22c6792caf8a4ca4317e3e9d273";
export const apikeyBugsnagBeta = "67a5275aa12dc423bc113ad69da9d93c";
export const apikeyBugsnagDev = "ce04cf79558d4491ae9fe634baf50882";

export const AppkeyAlicloud = "FFFF0N0N000000006429"; // 阿里云验证的appkey

// Plots 相关的图表
// https://charts.ant.design/zh/docs/manual/getting-started
// https://unpkg.com/@ant-design/charts@1.0.5/dist/charts.min.js  引入这个Area上的tootip展示异常, 且Pie角度失效

// 使用plot， Area上的tooltip无法展示
//export const ANT_DESDIGN_CHART_SRC = "https://unpkg.com/@ant-design/plots@latest/dist/plots.min.js";
export const ANT_DESDIGN_CHART_SRC = "https://unpkg.com/@ant-design/charts";

export const ALI_SDK_SRC = "https://gosspublic.alicdn.com/aliyun-oss-sdk-6.16.0.min.js";

export const FIXED_DECIMAL = 8;

// 代表实际币种时, USD需要转为USDC
export const BENCHMARK_CURRENCY = "USDC";

// 作为参考单位时, USD不需要转为USDC！！类似资产预估值、钱包总和等
export const REFERENCE_CURRENCY = "USD";

export const BENCHMARK_CURRENCY_SYMBOL = "$";
export const CAN_REQUEST_AFTER_LOGIN_APIS = ["logout", "symbols", "config"];
export const HOME_SYMBOLS = ["BTC_USD", "ETH_USD", "SOL_USD", "BNB_USD", "AVAX_USD"];
export const RECOMMEND_SYMBOLS = [
  "BTC_USD",
  "ETH_USD",
  "SOL_USD",
  "BNB_USD",
  "AVAX_USD",
  "FLOW_USD"
];
export const STRUCTURED_SYMBOLS = ["BTC_USD", "ETH_USD"];

// 12月份简称
export const MONTHS = [
  "Jan.",
  "Feb.",
  " Mar.",
  "Apr.",
  "May",
  "Jun.",
  "Jul.",
  "Aug.",
  "Sept.",
  "Oct.",
  "Nov.",
  "Dec."
];

import silverIcon from "@aspen/assets/images/vip/silver.png";
import silverLevelTxtImg from "@aspen/assets/images/vip/silver_txt.png";
import silverLevelTxtImgZh from "@aspen/assets/images/vip/silver_txt_zh.png";

import goldIcon from "@aspen/assets/images/vip/gold.png";
import goldLevelTxtImg from "@aspen/assets/images/vip/gold_txt.png";
import goldLevelTxtImgZh from "@aspen/assets/images/vip/gold_txt_zh.png";

import blackIcon from "@aspen/assets/images/vip/black.png";
import blackLevelTxtImg from "@aspen/assets/images/vip/black_txt.png";
import blackLevelTxtImgZh from "@aspen/assets/images/vip/black_txt_zh.png";
// 会员配置图片
export const VIP_ICON_INFO = {
  Silver: {
    cardBg: "/images/silver_bg.png",
    bg: "/images/silver_bg_large.png",
    icon: silverIcon,
    linearGradient: `linear-gradient(270deg, #FFFFFF 12.02%, #A2BDCB 48.38%, #454E52 97.96%, #131515 131.33%)`,
    levelTxtImg: silverLevelTxtImg,
    levelTxtImgZh: silverLevelTxtImgZh
  },
  Gold: {
    cardBg: "/images/gold_bg.png",
    bg: "/images/gold_bg_large.png",
    icon: goldIcon,
    linearGradient: `linear-gradient(270deg, #E0C7A3 0%, #BF9976 100%)`,
    levelTxtImg: goldLevelTxtImg,
    levelTxtImgZh: goldLevelTxtImgZh
  },
  Black: {
    cardBg: "/images/black_bg.png",
    bg: "/images/black_bg_large.png",
    icon: blackIcon,
    linearGradient: `linear-gradient(270deg, #DEDEDE 0%, #D8D8D8 100%)`,
    levelTxtImg: blackLevelTxtImg,
    levelTxtImgZh: blackLevelTxtImgZh
  },
  defaultVipBg: "/images/default_vip.png"
};
// 会员有效时长
export const MEMBER_EXPIRE_DAYS = 90;

// 理财产品类型：活期、定期、 staking、定投
export const YIELD_TYPES = {
  flexible: "flexible",
  fixed: "fixed",
  staking: "staking",
  DCA: "DCA"
};

// 所有产品种类 现货、理财、结构化理财、策略、风投
export const PRODUCT_TYPES = {
  spot: "spot",
  yield: "yield",
  structured: "structured",
  strategies: "strategies",
  ventures: "ventures"
};

// 双币产品类型：dual、累计期权
export const STRUCTURED_TYPES = {
  dual: "dual",
  accumulator: "accumulator",
  snowball: "snowball"
};

// staking的操作类型
export const STAKING_ACTION = {
  stake: "stake",
  unstake: "unstake"
};

// 风投 列表 等级
export const VENTURES_TAB_ACTION = {
  primary: "primary",
  secondary: "secondary"
};

export const MYDCA_PLAN_TAB = {
  all: "all",
  active: "active",
  cancelled: "cancelled"
};

export const EVENT_TYPES = {
  all: "all",
  online: "Online",
  inPerson: "inPerson"
};

// 登录页面需要展示错误提示框的错误码
export const LOGIN_ERROR_TIP_MSG = [
  "customer.login.password.error.email",
  "customer.login.password.error.mobile",
  "customer.login.password.error",
  "user.name.not.found",
  "user.password.error"
];

// 公用接口和参数一致的请求，可以在这里配置
export const CACHE_DEFAULT_OPTIONS = {
  // 在一段时间内只重新验证一次（以毫秒为单位）
  focusThrottleInterval: 5 * 60 * 1000, // 5分钟只重新验证一次
  // keepPreviousData设置为true，这意味着SWR将在数据请求期间保留先前的缓存数据。如果没有缓存数据，或者数据请求失败，data将为undefined
  keepPreviousData: true,
  revalidateOnFocus: false // 窗口聚焦时自动重新验证，默认值是true, 需要调整为false，否则点击页面任何地方时都可能触发重新验证
  // 在挂载组件时启用或禁用自动重新验(false: 禁用，true: 启用), 默认为true，在组件挂载时SWR会重新验证数据并进行请求。如果设置为false，SWR将只从缓存中读取数据，不会重新请求数据。
  // revalidateOnMount: true,
  // 请求成功完成时的回调函数，参数为请求返回的数据，key，以及SWR配置对象
  // onSuccess: (data, key, config) => {
  //   const _cacheData = config.cache.get(key).data
  //   if (isEqual(_cacheData, data)) {
  //     console.log("onSuccess _cacheData")
  //     // return data
  //   }
  // },
};
export const DEFAULT_COUNTRY = "+852_HK";
export const DEFAULT_PHONE_AFFIX = "+852";
export const GOOGLE_DOWNLOAD_LINK =
  "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US";

// 手机绑定入口屏蔽掉
export const OPEN_BIND_PHONE = true;

// 合规改造：注册页面agreement和policy是否显示
export const OPEN_POLICY = true;

export const showAunFee = false;

export const DEFAULT_PAGE_SIZE = 10;

// 分页组件初始化pageInfo
export const initPageInfo = { page: 1, nextToken: "" };

export const IP_COUNTRY_URL = "https://api.country.is/";
